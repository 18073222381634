import { graphql, useStaticQuery } from "gatsby";

export const useSiteAboutI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiAbout {
        aboutTitle
        aboutDescription
        celebration
        carouselTitle
      }
    }
  `)

  return data.strapiAbout;

}