import * as React from 'react';
import { useState } from 'react';

// --- ABOUT COMPONENT
const AboutDescription = (props: { 
  aboutTitle: string,             // -> Text title from Strapi
  aboutDescription: string        // -> Text description from Strapi
}) => {

  return (
    <div className="min-h-screen">
      <div className='flex flex-col'>
        <div className='text-xs uppercase md:text-sm lg:text-lg lg:flex'>about</div>
        <div className='hidden font-bold pt-7 text-7xl lg:flex'>{props?.aboutTitle && props.aboutTitle}</div>
        <div className='flex flex-col pt-8 text-2xl leading-tight text-center lg:pt-16 lg:w-3/4 lg:text-3xl 2xl:text-5xl lg:text-left'> {/* flex-wrap */}
          {/* { props?.aboutDescription && 
            props.aboutDescription.split(' ').map((letter: string, index: number) => {
              let letterStyle: any = {"animationDelay": (1 + index / 4) + "s"}
              if (letter === '--') {
                return (   
                  <div className='flex grow'>

                  </div>
                  // <div key={index.toString() + 'divAD'}>
                  //   <br key={index.toString() + 'brAD'}/>
                  //   <span key={index.toString() + 'blockAD'} className='block w-full p-2'/>
                  // </div>
                  // Cambiare p-2 con media query adeguate
                )
              }
              if (letter !== '--') {
                return (
                  <span key={index.toString() + 'letterAD'} 
                    className='inline-block pr-2 text-cardinal-white enlightLetter' 
                    style={letterStyle} dangerouslySetInnerHTML={{ __html: letter }} />
                )
              }
            })
          } */}
          { props?.aboutDescription && 
            props.aboutDescription.split('--').map((phrase: string, phraseIndex: number) => {
              let wordBuffer: number = 0;
              for (let k = 0; k < phraseIndex; k++) {
                wordBuffer = wordBuffer + props.aboutDescription.split('--')[k].split(' ').length;
              }

              return (
                <div key={phraseIndex.toString() + 'phraseAD'} className='pb-2'>
                  { phrase.split(' ').map((word: string, wordIndex: number) => {
                    let wordGlobalIndex: number = wordIndex + wordBuffer;
                    let wordStyle: any = {"animationDelay": (1 + wordGlobalIndex / 4) + "s"}
                    return (
                      <span key={wordGlobalIndex.toString() + 'letterAD'}
                        className='inline-block pr-2 text-cardinal-white enlightLetter'
                        style={wordStyle} dangerouslySetInnerHTML={{ __html: word }} />
                    )
                  })}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default AboutDescription;