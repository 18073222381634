import { useStaticQuery, graphql } from "gatsby"

export const useSiteAboutI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiAbout {
        localizations {
          data {
            attributes {
              aboutTitle
              aboutDescription
              celebration
              carouselTitle
            }
          }
        }
      }
    }  
  `)

  return data.strapiAbout.localizations.data[0].attributes;

}