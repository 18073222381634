import * as React from 'react';
import { SEO } from '../components/seo';
import AboutImages from './about-components/about-images';
import { useSiteStrapiAboutElements } from '../hooks/site-strapi-about-elements';
import AboutCelebration from './about-components/about-celebration';
import AboutDescription from './about-components/about-description';
import DefaultAppView from '../app-view/default-app-view';
import { StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import { CarouselElementData, SEOData } from '../constants/types';
import { getUserPreferredLocale } from '../functions/utils';
import { useSiteAboutI18N_IT } from '../hooks/site-strapi-i18n-IT/site-about-i18n-IT';
import { useSiteAboutI18N_EN } from '../hooks/site-strapi-i18n-EN/site-about-i18n-EN';

// --- ABOUT PAGE ---
const About = () => {

  const _userLocale: string = getUserPreferredLocale();

  // --- START QUERYS ---
  const strapiQuery: any | undefined = useSiteStrapiAboutElements();  
  const strapiI18NContent: any | undefined = (_userLocale.includes('it') || _userLocale.includes('IT')) ? useSiteAboutI18N_IT() : useSiteAboutI18N_EN();
  
  const carouselPictures = strapiQuery && strapiQuery?.carousel;
  const carouselData = strapiQuery && strapiQuery?.aboutCarouselElements;

  const carouselImageElements: CarouselElementData[] = carouselPictures.map((pictureElement: any) => {
    let elementLinkedinUrl = carouselData.filter((dataElement: any) => dataElement.elementName === pictureElement.alternativeText ? dataElement.elementLinkedinUrl : undefined);
    let elementGithubUrl = carouselData.filter((dataElement: any) => dataElement.elementName === pictureElement.alternativeText ? dataElement.elementGithubUrl : undefined);
    return (
      {
        caption: pictureElement?.caption,
        alternativeText: pictureElement?.alternativeText,
        localFile: pictureElement?.localFile,
        linkedinUrl: elementLinkedinUrl,
        githubUrl: elementGithubUrl
      }
    )
  })
  // ---

  return (
    <DefaultAppView currentUrl='/about' userLocale={_userLocale}>
      <div className='px-5 pt-20 bg-black md:px-16 sm:px-10 lg:px-36 lg:pt-40 text-cardinal-white'>
        <AboutDescription aboutTitle={strapiI18NContent?.aboutTitle} aboutDescription={strapiI18NContent?.aboutDescription}/>
      </div>
      <AboutImages carouselElements={carouselImageElements} carouselTitle={strapiI18NContent?.carouselTitle}/>
      <div className='px-5 bg-black md:px-16 sm:px-10 lg:px-36 text-cardinal-white'>
          <AboutCelebration celebration={strapiI18NContent?.celebration}/>
          <div className='bg-black lg:h-96'/>
      </div>
    </DefaultAppView>
  )
}

export default About;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiAbout {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData).strapiAbout.SEO;

  return (
    <SEO 
      title={seo.metaTitle} 
      description={seo.metaDescription} 
      keywords={seo.keywords} 
      preventIndexing={seo.preventIndexing} 
      pathname={seo.pathname}/>   
  )
}