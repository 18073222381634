import * as React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from '../functions/theme';

const Carousel = (props: {
  children: any[] | undefined,
  show: number
}) => {

  const {children, show} = {
    children: props.children ? props.children : [],
    show: props.show
  };

  const _isMobile: boolean = isMobile();

  // --- STATES
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [childrenLength, setChildrenLength] = useState<number>(children.length);
  const [touchPosition, setTouchPosition] = useState(null);
  // ---

  useEffect(() => {
    const three = children.slice(0,3);
    const threeRemapped = three.map((el: any, index: number) => {
      return {
        ...el, key: (childrenLength + index) + 'carouselImage'
      } 
    })
    children.push(...threeRemapped);
  }, [])

  // --- Hook w/ setting state for client
  useEffect(() => {
    setChildrenLength(children.length);
  }, [children, show])
  // ---

    
  // --- FUNCTIONS
  const handleRight = () => {
    if (currentImage < (childrenLength - show)) {
      setCurrentImage(prevState => (prevState + 1));
    }
  }
  // -

  const handleLeft = () => {
    if (currentImage > 0) {
      setCurrentImage(prevState => (prevState - 1));
    }
  }
  // -

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  }
  // -

  const handleTouchMove = (e: any) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const touchSwipeDist = touchDown - currentTouch;

    if (touchSwipeDist > 5) {
      handleRight();
    }
    if (touchSwipeDist < -5) {
      handleLeft();
    }

    setTouchPosition(null);
  }
  // ---

  return (
    <div className='carousel-container'>
      <div className='carousel-wrapper'>
        <div className='carousel-content-wrapper' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          <div className={`carousel-content show-${show} ${_isMobile ? 'carouselMobileAnimation' : 'carouselAnimation'}`}>
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carousel;