import * as React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { isMobile } from '../../functions/theme';
import { graphql, StaticQueryDocument, useStaticQuery } from 'gatsby';

gsap.registerPlugin(ScrollTrigger);

// --- ABOUT COMPONENT - images of us small updating fast on scroll
const AboutCelebration = (props: { 
  celebration: string,        //  -> Text from strapi
} | null) => {

  const _isMobile: boolean = isMobile();
    
  // --- Getting images from strapi and sorting
  const usSmallImagesQuery: any | undefined = data && useStaticQuery(data);
  const celebrationImgs = usSmallImagesQuery && usSmallImagesQuery.strapiAbout.usSmall.sort((a: any, b: any) => a?.caption > b?.caption ? 1 : -1);
  const randomizedImgs = celebrationImgs.sort(() => 0.5 - Math.random()).slice(0, 5);
  // ---

  // --- STATE
  const [imgCounter, setImgCounter] = useState<number>(0);
  // ---

  // --- Hook w/ container translation animation and scrollTrigger
  useEffect(() => {
    const photoUsAnim: gsap.core.Tween = gsap.to('#photoUsContainer', {
      y: _isMobile ? '-25%' : '-35%',
      ease: 'power0.in',
      duration: 0.1,
    })

    ScrollTrigger.create({
      trigger: '#usContainer',
      // markers: true,
      // id: 'photo',
      start: 'top bottom',
      end: 'bottom center',
      animation: photoUsAnim,
      toggleActions: 'play none reverse reset',
      scrub: 1,
    })
  }, [])
  // ---
    
  // --- Hook w/ change photo animation and scrollTrigger
  useEffect(() => {
    ScrollTrigger.create({
      trigger: '#usContainer',
      // markers: true,
      // id: 'images',
      start: 'center bottom',
      end: 'bottom center+=25%',
      toggleActions: 'play none reverse reset',
      scrub: 1,
      onUpdate: ({progress}) => {
        const relativeProgress = progress * 10;
        const numberOfImages: number = randomizedImgs ? (randomizedImgs?.length - 1) : 0;
        const imageEvry = 10 / numberOfImages;
        const counter = Math.round(relativeProgress / imageEvry);
        setImgCounter(counter);
      }
    })
  }, [imgCounter])
  // ---


  // ---Function for rendering updated image function from counter State
  const updateImage = (counter: number) => {
    let image: IGatsbyImageData = randomizedImgs && getImage(randomizedImgs[counter].localFile);

    return (
      <>
        <GatsbyImage image={image} alt={randomizedImgs && randomizedImgs[counter].caption} className='h-full' />
      </>
    )
  }
  // ---

    
  return (
    <div id='usContainer' className='h-screen lg:pt-52 lg:min-h-screen'>
      <div className="flex items-center justify-center h-full">
        <div className='relative flex w-full h-full lg:w-3/4'>
          <div id="photoUsContainer" className='absolute flex flex-col justify-center w-full h-full lg:justify-end'>
            <div className='flex items-center justify-center w-2/3 h-1/3 md:h-1/2 lg:w-3/5 xl:w-2/5 lg:h-4/5'>
              { updateImage(imgCounter) }
            </div>
          </div>
          <div className='z-10 flex items-center justify-end w-full h-full text-4xl leading-none md:text-5xl lg:text-6xl xl:text-8xl'>
            <div className='flex text-right uppercase w-72 sm:w-full md:w-4/5 lg:w-5/6'>
              {props?.celebration}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutCelebration;

// --- Graphql query getting us small images datas from site 
export const data: StaticQueryDocument | undefined = graphql`
  query {
    strapiAbout {
      usSmall {
        name
        caption
        alternativeText
        id
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`;
// ---