import { graphql, useStaticQuery } from "gatsby";

// HOOK - From STRAPI | Single Type: About | Getting about page content data
export const useSiteStrapiAboutElements = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiAbout {
        aboutCarouselElements {
          elementLinkedinUrl
          elementGithubUrl
          elementName
        }
        carousel {
          caption
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `)

  return data.strapiAbout;

}